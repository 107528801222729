import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
// import components from '@components/Mdx'
import { preToCodeBlock } from "mdx-utils"
import { Code } from "../../../components/Code"
import { Link as GLink } from 'gatsby'
import { Link } from 'theme-ui'

const ExternalLink = props => {
  if (props.href.includes('agrenpoint.com') || props.href.includes('localhost') || props.href[0] === '/') {
    return <Link variant='links.normal' as={GLink} to={props.href}>{props.children}</Link>
  }
  return (
    <Link variant='links.normal' target='_blank' href={props.href} rel='noopener noreferrer'>
      {props.children}
    </Link>
  )
}

const components = {
  pre: (preProps) => {
    const props = preToCodeBlock(preProps)
    if (props) {
      return <Code {...props} />
    } else {
      return <pre {...preProps} />
    }
  },
  a: ExternalLink
}

export const PostBody = ({ body }) => {
  return (
    <MDXProvider components={components}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  )
}
